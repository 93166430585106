import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["search", "clear", "active", "form"];

  connect() {
    this.toggleClearButton();
  }

  searchKeyup(event) {
    if (event.key === "Enter") {
      this.submitForm();
    } else {
      this.toggleClearButton();
    }
  }

  activeChange() {
    this.submitForm();
  }

  clearFilters() {
    this.searchTarget.value = "";
    this.activeTarget.value = "true";
    this.submitForm();
  }

  submitForm() {
    this.formTarget.requestSubmit();
  }

  toggleClearButton() {
    const hasFilters = this.searchTarget.value.trim() !== "" || this.activeTarget.value !== "true";
    this.clearTarget.classList.toggle("tw-hidden", !hasFilters);
  }
}
